import $ from 'jquery';

/**
 * Form controller
 */

// let textOpened = $('#extendedRules').text();
// let textClosed = $('#commonRules').text();

// let opened = false;
// $('.page--form--checkbox--more, .page--form--checkbox--content--text').on('click', function () {
//     if (opened) {
//         $('.page--form--checkbox--content--text').html(textClosed);
//         opened = false;
//     } else {
//         $('.page--form--checkbox--content--text').html(textOpened);
//         opened = true;
//     }
// });

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

let errors = [];
function validate() {
	
    let validate = true;
    errors = [];
    $('.page-form form input:not(:radio)').each(function (i, e) {
        let input = $(e);

        if (input.attr('type') == 'text') {
            if (input.val() == '') {
                input
                    .siblings('.validation-text')
                    .addClass('show');
                input.addClass('validate');
                validate = false;
                errors.push(input.siblings('label').html());
            }
        } else if (input.attr('type') == 'email') {
            if (!validateEmail(input.val())) {
                input
                    .siblings('.validation-text')
                    .addClass('show');
                input.addClass('validate');
                validate = false;
                errors.push(input.siblings('label').html());
            }
        } else if (input.attr('type') == 'text' && input.is('.tel')) {
            let value = input.val();

            if (value == '' || !$.isNumeric(value)) {
                input
                    .siblings('.validation-text')
                    .addClass('show');
                input.addClass('validate');
                validate = false;
                errors.push(input.siblings('label').html());
            }
        } else if (input.attr('type') == 'checkbox') {
            if (!input.is(':checked')) {
                let element = input
                    .closest('.page-form--checkbox');
                element.find('.page-form--checkbox--content').addClass('validate');
                element.find('.validation-text').addClass('show');
                validate = false;
            }
        } else if ($('#form_code').val() == '') {
            input
                .siblings('.validation-text')
                .addClass('show');
            input.addClass('validate');
            validate = false;
            errors.push(input.siblings('label').html());
        }
    });
	let input = $('.page-form form :radio:checked');
	if (!input.length) {
		let element = $('.page-form form :radio:first')
			.closest('.page-form--checkbox');
		element.find('.page-form--checkbox--content').addClass('validate');
		element.find('.validation-text').addClass('show');
		validate = false;
	}
    errors = errors.join(', ');
    return validate;
}

let isEntered = false;
$('.page-form form input').on('keyup', function () {
    $(this).removeClass('validate');
    $(this)
        .siblings('.validation-text')
        .removeClass('show');
    if (!isEntered) {
        gasend('send', 'event', 'Formularz', 'Rozpoczęcie wypełniania danych');
        isEntered = true;
    }
});

$('.page-form form input[type="checkbox"], .page-form form input[type="radio"]').on('change', function () {
    let element = $(this)
        .closest('.page-form--checkbox');
	element.find('.page-form--checkbox--content').removeClass('validate');
    element.find('.validation-text').removeClass('show');
});

$('.page-form form').on('submit', function (ev) {
    if (validate()) {
        $('.preloader').removeClass('hide-me');
        gasend('send', 'event', 'Formular', 'Navstevnik', 'Odeslano', '1');
    } else {
		ev.preventDefault();
        gasend('send', 'event', 'Formular', 'Navstevnik', 'Chyby - ' + errors, '0');
    }
});


$('.another-code').on('click', function (ev) {
//    ev.preventDefault();
//    $('form').removeClass('hide');
//    $('form input').val('');
//    $('form input[type="checkbox"').prop('checked', false);
//    $('.thank-you-content').addClass('hide');
    gasend('send', 'event', 'Thankyou', 'Pridat dalsi kod');
});