import $ from 'jquery';

let $navToggler = $('.js-nav-toggler'),
    $menu = $('#menu');

function smoothScroll(target) {
    $('body, html').animate({
        'scrollTop': target.offset().top
    }, 500);
}

function scrollToInit() {
    $('.js-scrollTo').on('click', function(e) {
        e.preventDefault();

        $menu.find('.menu__list').fadeOut();
        $menu.removeClass('menu--open');
        $('body').removeClass('is-open');
        $navToggler.removeClass('open');

        smoothScroll($(this.hash));
    });

    $('.js-scrollToHash').on('click', function(e) {
        var $evoker = $(this);
        var hash = $evoker.attr('href').split('#')[1];
        if (hash) {
            var $alter = $('*[data-hash="' + hash + '"]');
            if ($alter.length) {
                e.preventDefault();

                $menu.find('.menu__list').fadeOut();
                $menu.removeClass('menu--open');
                $('body').removeClass('is-open');
                $navToggler.removeClass('open');
                smoothScroll($alter);
            }
        }
    });
}

function autoScrollInit() {
    if (window.location.hash) {
        if ($(window.location.hash).length) {
            smoothScroll($(window.location.hash));
        }

        var $alter = $('*[data-hash="' + window.location.hash.substr(1) + '"]');
        if ($alter.length) {
            smoothScroll($alter);
        }
    }
}

export { scrollToInit, autoScrollInit };
