import $ from 'jquery';

/**
 * Popup's controller
 */


const popup = {

    init: () => {
        popup.getContentToPopup();
    },

    eventFactory: () => {
        $('.js-popup').on('click', function() {
            popup.openPopup($(this).data('popup'));
        });

        $('.page-form--gamesitem button, .prices-games-item button').on('click', function(){
            popup.openPopup($(this).data('popup'), $(this).data('name'));
        });

        $('.popup-close').on('click', () => {
            popup.closePopup();
        });
    },

    openPopup: (type, name) => {
        console.log('aaa');
        $('body').addClass('no-scroll');
        switch (type) {
            case 'about':
                console.log('about');
                popup.aboutPopup();
                break;
            case 'build':
                console.log('build');
                popup.buildPopup();
                break;
            case 'list':
                console.log('list');
                popup.listPopup();
                break;
            case 'game':
                console.log('game');
                popup.gamePopupAlt(name);
                break;
            case 'download':
                console.log('download');
                popup.downloadPopup();
                break;
            case 'code':
                console.log('code');
                popup.codePopup();
                break;
            case 'router':
                console.log('router');
                popup.routerPopup();
                break;
            case 'faq':
                console.log('faq');
                popup.faqPopup();
                break;
            default:
                console.log('default');
        }
    },
    aboutPopup: () => {
        $('#popup').removeClass('hidden');
        console.log(popup);
        $('.popup-title').html(popup.data.about.popuptitle);
        let html = `
            <div class="popup-about">
                <div class="img-box"><img src="${popup.data.about.img}" /></div>
                <div class="right-section">
                    <span class="title">
                        ${popup.data.about.title}
                    </span>
                    <div class="description">
                        ${popup.data.about.description}
                    </div>
                    <div class="subdescription">
                        ${popup.data.about.subdescription}
                    </div>
                </div>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
    },
    buildPopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.build.popuptitle);
        let html = `
            <div class="popup-build">
                <button class="arrow left-arrow"></button>
                <div class="build-popup-inner">
                    <div class="tab1 tabs">
                        <div class="build-box">
                            <img src="${popup.data.build.parks[0].img}" />
                            <span>${popup.data.build.parks[0].text}</span>
                        </div>
                        <div class="build-box">
                            <img src="${popup.data.build.parks[1].img}" />
                            <span>${popup.data.build.parks[1].text}</span>
                        </div>
                    </div>
                    <div class="tab2 tabs hidden">
                        <div class="build-box">
                            <img src="${popup.data.build.parks[2].img}" />
                            <span>${popup.data.build.parks[2].text}</span>
                        </div>
                        <div class="build-box">
                            <img src="${popup.data.build.parks[3].img}" />
                            <span>${popup.data.build.parks[3].text}</span>
                        </div>
                    </div>
                </div>
                <button class="arrow right-arrow"></button>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        $('.popup-build .arrow').on('click', () => {
            $('.tab1').toggleClass('hidden');
            $('.tab2').toggleClass('hidden');
        });
        
    },
    listPopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.list.popuptitle);
        const html = `
            <div class="popup-list">
                <div class="game-box">
                    <img src="${popup.data.list.fifa.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.fifa.title}</span>
                        <button data-name="${popup.data.list.fifa.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
                <div class="game-box">
                    <img src="${popup.data.list.needforspeed.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.needforspeed.title}</span>
                        <button data-name="${popup.data.list.needforspeed.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
                <div class="game-box">
                    <img src="${popup.data.list.nhl.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.nhl.title}</span>
                        <button data-name="${popup.data.list.nhl.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
                <div class="game-box">
                    <img src="${popup.data.list.plants.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.plants.title}</span>
                        <button data-name="${popup.data.list.plants.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
                <div class="game-box">
                    <img src="${popup.data.list.sims.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.sims.title}</span>
                        <button data-name="${popup.data.list.sims.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
                <div class="game-box">
                    <img src="${popup.data.list.unravel.img}" />
                    <div class="button-box">
                        <span>${popup.data.list.unravel.title}</span>
                        <button data-name="${popup.data.list.unravel.data}"><span>${popup.data.list.next}</span></button>
                    </div>
                </div>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        $('.popup-list button').on('click', function(){
            popup.gamePopup($(this).data('name'));
        });
    },
    downloadPopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.download.popuptitle);
        let html = `
            <div class="popup-download">
                <div class="buttons">
                    <button class="button-tab tab-one active"><span>${popup.data.download.button1}</span></button>
                    <button class="button-tab tab-two"><span>${popup.data.download.button2}</span></button>
                </div>
                <div class="tabs">
                    <div class="tab1">
                        <div class="left">
                            <span class="yellow">${popup.data.download.tabs.tab1.left.title}</span>
                            <ul>
                                <li><span class="yellow">1. </span><span>${popup.data.download.tabs.tab1.left.lines[0]}</span></li>
                                <li><span class="yellow">2. </span><span>${popup.data.download.tabs.tab1.left.lines[1]}</span></li>
                                <li><span class="yellow">3. </span><span>${popup.data.download.tabs.tab1.left.lines[2]}</span></li>
                                <li><span class="yellow">4. </span><span>${popup.data.download.tabs.tab1.left.lines[3]}</span></li>
                                <li><span class="yellow">5. </span><span>${popup.data.download.tabs.tab1.left.lines[4]}</span></li>
                                <li><span class="yellow">6. </span><span>${popup.data.download.tabs.tab1.left.lines[5]}</span></li>
                                <li><span class="yellow">7. </span><span>${popup.data.download.tabs.tab1.left.lines[6]}</span></li>
                            </ul>
                        </div>
                        <div class="right">
                            <span class="yellow">${popup.data.download.tabs.tab1.right.title}</span>
                            <ul>
                                <li><span class="yellow">1. </span><span>${popup.data.download.tabs.tab1.right.lines[0]}</span></li>
                                <li><span class="yellow">2. </span><span>${popup.data.download.tabs.tab1.right.lines[1]}</span></li>
                                <li><span class="yellow">3. </span><span>${popup.data.download.tabs.tab1.right.lines[2]}</span></li>
                                <li><span class="yellow">4. </span><span>${popup.data.download.tabs.tab1.right.lines[3]}</span></li>
                                <li><span class="yellow">5. </span><span>${popup.data.download.tabs.tab1.right.lines[4]}</span></li>
                                <li><span class="yellow">6. </span><span>${popup.data.download.tabs.tab1.right.lines[5]}</span></li>
                            </ul>
                            <a style="text-decoration: underline;" href="${popup.data.download.tabs.tab1.right.lines[6]}" target="_blank">${popup.data.download.tabs.tab1.right.lines[7]}</a>
                        </div>
                    </div>
                    <div class="tab2 hidden">
                        <span class="yellow">${popup.data.download.tabs.tab2.title}</span>
                        <ul>
                            <li><span class="yellow">1. </span><span>${popup.data.download.tabs.tab2.lines[0]}</span></li>
                            <li><span class="yellow">2. </span><span>${popup.data.download.tabs.tab2.lines[1]}</span></li>
                            <li><span class="yellow">3. </span><span>${popup.data.download.tabs.tab2.lines[2]}</span></li>
                            <li><span class="yellow">4. </span><span>${popup.data.download.tabs.tab2.lines[3]}</span></li>
                        </ul>
                        <a style="color: white; text-decoration: underline;" href="${popup.data.download.tabs.tab2.lines[4]}" target="_blank">${popup.data.download.tabs.tab2.lines[5]}</a>
                    </div>
                </div>
                <div class="description">
                    <span>${popup.data.download.description}</span>
                    <span class="yellow">${popup.data.download.subdescription}</span>
                </div>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        $('.button-tab').on('click', () => {
            $('.tab1').toggleClass('hidden');
            $('.tab2').toggleClass('hidden');
            $('.tab-one').toggleClass('active');
            $('.tab-two').toggleClass('active');
        });
    },
    codePopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.code.popuptitle);
        let html = `
            <div class="popup-code">
                <div><a href="https://play.google.com/store/apps/details?id=com.ea.game.simcitymobile_row" target="_blank"><img src="${popup.data.code.img}" /></a></div>
                <ul>
                    <li><span class="yellow"></span><span>${popup.data.code.lines[0]}</span></li>
                    <li><span class="yellow">1. </span><span>${popup.data.code.lines[1]}</span></li>
                    <li><span class="yellow">2. </span><span>${popup.data.code.lines[2]}</span></li>
                    <li><span class="yellow">3. </span><span>${popup.data.code.lines[3]}</span></li>
                    <li><span class="yellow">4. </span><span>${popup.data.code.lines[4]}</span></li>
                    <li><span class="yellow">5. </span><span>${popup.data.code.lines[5]}</span></li>
                    <li><span class="yellow">6. </span><span>${popup.data.code.lines[6]}</span></li>
                </ul>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        
    },
    routerPopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.router.popuptitle);
        let html = `
            <div class="popup-router">
                <p class="yellow">${popup.data.router.description}</p>
                <ul>
                    <li>${popup.data.router.lines[0]}</li>
                    <li>${popup.data.router.lines[1]}</li>
                    <li>${popup.data.router.lines[2]}</li>
                    <li>${popup.data.router.lines[3]}</li>
                    <li>${popup.data.router.lines[4]}</li>
                    <li>${popup.data.router.lines[5]}</li>
                    <li>${popup.data.router.lines[6]}</li>
                    <li>${popup.data.router.lines[7]}</li>
                    <li>${popup.data.router.lines[8]}</li>
                </ul>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        
    },
    faqPopup: () => {
        $('#popup').removeClass('hidden');
        $('.popup-title').html(popup.data.faq.popuptitle);
        let html = `
            <div class="popup-faq">
                <dl>
                    <dt><span class="yellow">${popup.data.faq.questions[0]}</span></dt>
                    <dd>${popup.data.faq.answers[0]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[1]}</span></dt>
                    <dd>${popup.data.faq.answers[1]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[2]}</span></dt>
                    <dd>${popup.data.faq.answers[2]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[3]}</span></dt>
                    <dd>${popup.data.faq.answers[3]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[4]}</span></dt>
                    <dd>${popup.data.faq.answers[4]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[5]}</span></dt>
                    <dd>${popup.data.faq.answers[5]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[6]}</span></dt>
                    <dd>${popup.data.faq.answers[6]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[7]}</span></dt>
                    <dd>${popup.data.faq.answers[7]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[8]}</span></dt>
                    <dd>${popup.data.faq.answers[8]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[9]}</span></dt>
                    <dd>${popup.data.faq.answers[9]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[10]}</span></dt>
                    <dd>${popup.data.faq.answers[10]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[11]}</span></dt>
                    <dd>${popup.data.faq.answers[11]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[12]}</span></dt>
                    <dd>${popup.data.faq.answers[12]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[13]}</span></dt>
                    <dd>${popup.data.faq.answers[13]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[14]}</span></dt>
                    <dd>${popup.data.faq.answers[14]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[15]}</span></dt>
                    <dd>${popup.data.faq.answers[15]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[16]}</span></dt>
                    <dd>${popup.data.faq.answers[16]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[17]}</span></dt>
                    <dd>${popup.data.faq.answers[17]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[18]}</span></dt>
                    <dd>${popup.data.faq.answers[18]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[19]}</span></dt>
                    <dd>${popup.data.faq.answers[19]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[20]}</span></dt>
                    <dd>${popup.data.faq.answers[20]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[21]}</span></dt>
                    <dd>${popup.data.faq.answers[21]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[22]}</span></dt>
                    <dd>${popup.data.faq.answers[22]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[23]}</span></dt>
                    <dd>${popup.data.faq.answers[23]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[24]}</span></dt>
                    <dd>${popup.data.faq.answers[24]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[25]}</span></dt>
                    <dd>${popup.data.faq.answers[25]}</dd>
                    <dt><span class="yellow">${popup.data.faq.questions[26]}</span></dt>
                    <dd>${popup.data.faq.answers[26]}</dd>
                </dl>
            </div>
        `;
        $('.popup-content').html(html);
        $('#popup').height($('.popup-inner').height());
        $('.popup-scroll').height($('.popup-inner').height() + 200);
        $('.popup-faq .js-popup').on('click', function(){
            console.log('code');
            popup.codePopup();
        });
    },
    gamePopup: type => {
        const json = popup.data.list;
        for (const key in json) {
            if (key == type) {
                console.log(json[type]);
                $('.popup-title').html(json[type].popuptitle);
                let html = `
                    <div class="popup-about game">
                        <div class="img-box">
                            <img src="${json[type].about.img1}" />
                            <img src="${json[type].about.img2}" />
                        </div>
                        <div class="right-section">
                            <span class="title">
                                ${json[type].about.title}
                            </span>
                            <div class="description">
                                ${json[type].about.description}
                            </div>
                            <div class="subdescription">
                                ${json[type].about.subdescription}
                            </div>
                            <button><span>${json.button}</span></button>
                        </div>
                    </div>
                `;
                $('.popup-content').html(html);
            }
        };
        $('.popup-about button').on('click', function(){
            console.log('list');
            popup.listPopup();
        });
    },
    gamePopupAlt: type => {
        $('#popup').removeClass('hidden');
        console.log(type);

        const json = popup.data.list;
        console.log(json);
        for (const key in json) {
            if (key == type) {
                console.log(json[type]);
                $('.popup-title').html(json[type].popuptitle);
                let html = `
                    <div class="popup-about game">
                        <div class="img-box">
                            <img src="${json[type].about.img1}" />
                            <img src="${json[type].about.img2}" />
                        </div>
                        <div class="right-section">
                            <span class="title">
                                ${json[type].about.title}
                            </span>
                            <div class="description">
                                ${json[type].about.description}
                            </div>
                            <div class="subdescription">
                                ${json[type].about.subdescription}
                            </div>
                        </div>
                    </div>
                `;
                $('.popup-content').html(html);
                $('#popup').height($('.popup-inner').height());
                $('.popup-scroll').height($('.popup-inner').height() + 200);
            }
        };
    },
    closePopup: () => {
        $('body').removeClass('no-scroll');
        $('.popup-content').empty();
        $('.popup-title').empty();
        $('#popup').addClass('hidden');
    },
    getContentToPopup: () => {
        $.ajax({
            type: 'GET',
            url: '/popup.json',
            success: res => {
                console.log(res)
                popup.eventFactory();
                popup.data = res;

                if (window.location.hash) {
                    if (window.location.hash === '#popup-download') {
                        popup.downloadPopup();
                    }
                    if (window.location.hash === '#popup-code') {
                        popup.codePopup();
                    }
                    if (window.location.hash === '#popup-rules') {
                        popup.codePopup();
                    }
                    if (window.location.hash === '#popup-faq') {
                        popup.faqPopup();
                    }
                }
            },
            error: res => {
                console.info('error text', res);
            }
        });
    }

};

window.addEventListener('load', () => {
    popup.init();
});
