import $ from 'jquery';

let $navToggler = $('.js-nav-toggler'),
    $menu       = $('#menu'),
    $triger     = $('.menu__trigger'),
    $menuClose  = $('.menu-mobile .js-menu-close');

$navToggler.on('click', toggleMenu);
$('.js-menu-close').on('click', closeMenu);
$triger.on('click', openMenu);
$menuClose.on('click', closeMenuMobile);

/**
 * toggleMenu - open and close menu menu mobile
 */
function toggleMenu() {
    $navToggler.toggleClass('open');
    $('body').toggleClass('is-open');

    if ($('body').hasClass('is-open')) {
        if (typeof ga === 'function') gasend('send', 'event', 'Menu', 'Rozwinięcie menu');
    }
}

/**
 * toggleMenu - close menu mobile
 */
function closeMenuMobile() {
    $('body').removeClass('is-open');
    $navToggler.removeClass('open');
}

/**
 * toggleMenu - open menu desktop
 */
function openMenu(e){
    e.preventDefault();
    if (!$menu.hasClass('menu--open') && (!$(e.target).hasClass('js-menu-close'))){
        $menu.find('.menu__list').fadeIn();
        $menu.addClass('menu--open');

        if (typeof ga === 'function') gasend('send', 'event', 'Menu', 'Rozwinięcie menu');
    }
}

/**
 * toggleMenu - close menu desktop
 */
function closeMenu(){
    $menu.find('.menu__list').fadeOut();
    $menu.removeClass('menu--open');
}
