// import 'babel-polyfill';     // uncomment this if you want to use for...of,
// async/await or other things that require babel runtime

import Preloader from './modules/preloader';
import { scrollToInit, autoScrollInit } from './modules/smoothScroll.js';
import './modules/menu';
import './modules/isMobile';
import $ from 'jquery';
import './modules/pack-slider.js';
import './modules/popup.js';
import './modules/form.js';
import './modules/winners.js';

window.addEventListener('load', () => {
    Preloader.hide();

    $('.home-top--go-down').on('click', () => {
        $('body').animate({
            'scrollTop': $('.home-main-content')
                .offset()
                .top
        }, 500);
    });

    scrollToInit();
    autoScrollInit();
});




let awardsDone = false;
let instructionsDone = false;
let packsDone = false;

/**
 * Send GA event on scroll page to section.
 */
$(window).on('scroll', function () {
    let windowHeight = $(window).height();
    let scrollTop = $(document).scrollTop();
	if($('.arrows-container-top').length && $('.arrows-container-footer').length)
	{
		let offsetTop = $('.arrows-container-top')
			.offset()
			.top;
		let offsetTop2 = $('.arrows-container-footer')
			.offset()
			.top;

		if ((scrollTop + windowHeight >= offsetTop) && !awardsDone) {
			awardsDone = true;
			gasend('send', 'event', 'Homepage', 'Scroll', 'Oceneni');
			// console.log('top')
		}
		if ((scrollTop >= offsetTop) && !instructionsDone) {
			instructionsDone = true;
			gasend('send', 'event', 'Homepage', 'Scroll', 'Zasady');
			// console.log('srodek')
		}
		if (scrollTop + windowHeight >= offsetTop2 && !packsDone) {
			packsDone = true;
			gasend('send', 'event', 'Homepage', 'Scroll', 'Balicky');
		}
	}
});