import $ from 'jquery';
import slick from 'slick-carousel';
import { isMobile } from '../modules/isMobile';


/**
 * Home page slider
 */


const slider = $('.pack-slider--packs');

let slides = 3;
//&& $(window).width() < 600
if(isMobile() && $(window).width() < 768){
    slides = 1;
}

function optionsSlider() {
    return {
        vertical: false,
        verticalSwiping: false,
        slidesToShow: slides,
        speed: 400,
        centerMode: true,
        centerPadding: '0px',
        arrows: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: true,
                    // centerPadding: '40px',
                    slidesToShow: slides
                }
            },
            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    // centerPadding: '40px',
                    slidesToShow: slides
                }
            }]
    }
}

window.addEventListener('load', () => { 
    


    slider.slick(optionsSlider());

    $('.pack-slider--arrow--right').click(() => {
        slider.slick('slickNext');
    });

    $('.pack-slider--arrow--left').click(() => {
        slider.slick('slickPrev');
    });

    
});

window.addEventListener('orientationchange', ()=> { 
    // reinit slick
    setTimeout(function(){ 
        slider.slick('unslick');
        slider.slick(optionsSlider());
    }, 500);
    
});

