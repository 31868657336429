import $ from 'jquery';

/**
 * Winners page
 */

$('.winners-container--date-picker--content--button').on('click', function () {
    if ($(this).hasClass('show')) {
        $('.winners-container--date-picker--content--dates').removeClass('show');
        $(this).removeClass('show');
    } else {
        $('.winners-container--date-picker--content--dates').addClass('show');
        $(this).addClass('show');
    }
});

let chosenAward = 0;
$('.winners-container--menu-buttons button').on('click', function () {
    chosenAward = $(this).attr('data-name');
});

/**
 * Send GA event
 */
$('.winners-container--date-picker--content--dates button').on('click', function () {
    if (chosenAward == 'ps4') {
        gasend('send', 'event', 'Lista laureatów', 'Wybór tygodnia PS4 + PS VR', $(this).html());
    } else if (chosenAward == 'games') {
        gasend('send', 'event', 'Lista laureatów', 'Wybór dnia Gry od Electronic Arts', $(this).html());
    } else if (chosenAward == 'money') {
        gasend('send', 'event', 'Lista laureatów', 'Wybór dnia Sto złotych', $(this).html());
    }
});

$('body').on('click', function (ev) {
    if (!$(ev.target).closest('.winners-container--date-picker--content--button').length) {
        $('.winners-container--date-picker--content--dates').removeClass('show');
        $('.winners-container--date-picker--content--button').removeClass('show');
    }
});